function loadScript(src, done) {
  var js = document.createElement("script");
  js.src = src;
  js.onload = function () {
    done();
  };
  js.onerror = function () {
    done(new Error("Failed to load script " + src));
  };
  document.head.appendChild(js);
}

if (!window.Promise) {
  loadScript(
    "https://cdn.jsdelivr.net/npm/es6-promise@4/dist/es6-promise.auto.js"
  );
}

import Vue from "vue/dist/vue.js";
import galite from "ga-lite";

galite("create", "UA-72869-16", "auto");
galite("send", "pageview");

var app = new Vue({
  el: "#app",
  data: {
    expanded: false,
    working: false,
    submitAttempted: false,
    submitSuccess: false,
    submitError: false,
    prefError: "",
    addressError: "",
    services: [],
    response: "",
    name: "",
    phone: "",
    email: "",
    address: "",
    city: "",
    state: "MN",
    postalCode: "",
    type: "",
    comments: "",
    dirty: {
      name: false,
      email: false,
      phone: false,
    },
  },
  mounted() {
    //this.$refs.form.classList.add("hidden");
    let mq = window.matchMedia("(min-width: 768px)");
    window.addEventListener("resize", () => {
      if (mq.matches) {
        this.expanded = !mq.matches;
      }
    });
  },
  methods: {
    showEstimate() {
      if (window.matchMedia("(max-width: 767px)").matches) {
        this.expanded = !this.expanded;
      }
    },
    submit() {
      if (this.working) {
        return false;
      }

      this.submitAttempted = true;
      this.dirty.name = true;
      this.dirty.email = true;
      this.dirty.phone = true;

      if (this.validate()) {
        this.working = true;
        this.submitSuccess = false;
        this.submitError = false;

        return this.sendFormData()
          .then(() => {
            this.submitSuccess = true;
          })
          .catch((e) => {
            this.submitError = true;
          })
          .finally(() => {
            this.working = false;
            let topEl = this.$refs.form.parentElement;
            let targetY = topEl.offsetTop - 20;
            window.scrollTo(0, Math.min(window.scrollY, targetY));
          });
      }
    },
    sendFormData() {
      return new Promise((resolve, reject) => {
        const XHR = new XMLHttpRequest();

        let urlEncodedData = "";
        let dataEntries = [];

        // Turn the data object into an array of URL-encoded key/value pairs.
        if (this.services.length > 0) {
          dataEntries.push(
            "services=" + encodeURIComponent(this.services.join(","))
          );
        }

        [
          "response",
          "name",
          "phone",
          "email",
          "address",
          "city",
          "state",
          "postalCode",
          "type",
          "comments",
        ].forEach((field) => {
          dataEntries.push(
            encodeURIComponent(field) + "=" + encodeURIComponent(this[field])
          );
        });

        // Combine the pairs into a single string and replace all %-encoded spaces to
        // the '+' character; matches the behaviour of browser form submissions.
        urlEncodedData = dataEntries.join("&").replace(/%20/g, "+");

        // Define what happens on successful data submission
        XHR.addEventListener("load", function (event) {
          let target = event.target;
          let status = target && target.status;
          if (status >= 200 && status < 300) {
            resolve();
          } else {
            reject(`Http Status: ${status}`);
          }
        });

        // Define what happens in case of error
        XHR.addEventListener("error", function (event) {
          reject(event);
        });

        // Set up our request
        XHR.open("POST", this.$refs.form.getAttribute("action"));

        // Add the required HTTP header for form data POST requests
        XHR.setRequestHeader(
          "Content-Type",
          "application/x-www-form-urlencoded"
        );

        // Finally, send our data.
        XHR.send(urlEncodedData);
      });
    },
    validate() {
      this.prefError =
        this.response.length === 0 ? "Please choose an option above" : "";

      this.addressError = this.isAddressValid ? "" : "Please enter a complete address";

      if (
        this.prefError.length > 0 || 
        this.addressError.length > 0 || 
        this.hasInputError
      ) {
        return false;
      }

      return true;
    },
  },
  computed: {
    showForm() {
      return !this.showError && !this.showSuccess;
    },
    showError() {
      return this.submitError;
    },
    showSuccess() {
      return this.submitSuccess;
    },
    submitButtonText() {
      return this.working ? "Sending, please wait..." : "Send Estimate Request";
    },
    prefs() {
      return [
        { value: "email", label: "Email" },
        { value: "phone", label: "Phone" },
        { value: "text", label: "Text" },
      ];
    },
    formHasErrors() {
      return this.prefError || this.hasInputError;
    },
    hasInputError() {
      return this.nameError || this.phoneError || this.emailError;
    },
    isNameValid() {
      return this.name.trim().length > 0;
    },
    nameError() {
      if (this.dirty.name && !this.isNameValid) {
        return "Name is required";
      }
    },
    isEmailValid() {
      var atIndex = this.email.indexOf("@");
      var dotIndex = this.email.indexOf(".", Math.max(0, atIndex));
      return this.email.length >= 5 && atIndex >= 1 && dotIndex >= 3;
    },
    isEmailRequired() {
      return this.response === "email";
    },
    emailError() {
      if (this.dirty.email) {
        if (this.isEmailRequired && this.email.trim().length === 0) {
          return "Email is required";
        }

        if (
          (this.isEmailRequired || this.email.trim().length > 0) &&
          !this.isEmailValid
        ) {
          return "Invalid email address";
        }
      }
    },
    isPhoneRequired() {
      return this.response === "phone" || this.response === "text";
    },
    isPhoneValid() {
      var valid = true;
      var chars = this.phone.replace(/[\(\)\.\s\-]+/g, "").split("");
      if (chars.length !== 10) {
        return false;
      }
      for (var i = 0; i < chars.length; i++) {
        var nan = isNaN(parseInt(chars[i], 10));
        if (nan) {
          valid = false;
        }
      }
      return valid;
    },
    phoneError() {
      if (this.dirty.phone) {
        if (this.isPhoneRequired && this.phone.trim().length === 0) {
          return "Phone is required";
        }

        if (
          (this.isPhoneRequired || this.phone.trim().length > 0) &&
          !this.isPhoneValid
        ) {
          return "Enter a 10 digit phone number";
        }
      }
    },
    isAddressValid() {
      let hasAddress = this.address.trim().length > 0;
      let hasCity = this.city.trim().length > 0;
      let hasState = this.state.trim().length > 0;
      let hasPostalCode = this.postalCode.trim().length > 0;
      let state = this.state.trim();

      if (state === "MN") {
        if (!hasAddress && !hasCity && !hasPostalCode) {
          return true;
        }

        if (hasAddress && hasCity && hasPostalCode) {
          return true;
        }

        return false;
      }

      if (!hasAddress && !hasCity && !hasState && !hasPostalCode) {
        return true;
      }

      if (hasState && state !== "MN" && hasAddress && hasCity && hasPostalCode) {
        return true;
      }
      
      return false;
    }
  }
});
